import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html", function() {
    wow.init();
});
$('#block-title-where-work').load("block-title-where-work.html", function() {
    wow.init();
});
$('#block-list-where-work').load("block-list-where-work.html", function() {
    wow.init();
});
$('#block-banner-telephone').load("block-banner-telephone.html", function() {
    wow.init();
});
$('#block-list-have-budget').load("block-list-have-budget.html", function() {
    wow.init();
});
$('#block-title-contact-us').load("block-title-contact-us.html", function() {
    wow.init();
});
$('#block-contact-us').load("block-contact-us.php", function() {
    wow.init();
});
$('#block-services-list-links').load("block-services-list-links.html", function() {
    wow.init();
});

/*Cambio de estilos en el header*/
$(window).on("scroll", function(e) {

    if (parseInt(Math.round($(window).scrollTop())) > 50) {
        $('header').addClass('scroll-active');
    } else {
        $('header').removeClass('scroll-active');
    }
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();